
import { computed, defineComponent, PropType } from 'vue'
import { Timetable } from '@/kentico-api/routes.api'
import { formatDate } from '@/helpers'

export default defineComponent({
  props: {
    timetable: {
      type: Object as PropType<Timetable>,
      required: true
    },
    routeName: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const title = computed(() => props.timetable.validFrom && props.timetable.validFrom !== ''
      ? 'Revised Timetable'
      : 'Current Timetable'
    )
    const efectiveDate = computed(() => {
      if (!props.timetable.validFrom) return null

      return formatDate(props.timetable.validFrom)
    })

    const download = () => {
      props.timetable.timetableFile.forEach(file => {
        const link = document.createElement('a')
        link.setAttribute('href', file.url)
        link.setAttribute('target', '_blank')
        link.setAttribute('download', file.name || 'timetable')
        document.body.appendChild(link)
        link.click()
      })
    }
    return { title, efectiveDate, download }
  }
})
