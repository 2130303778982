
import { computed, defineComponent } from 'vue'
import router from '@/router'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import CardScroll from '@/components/CardScroll.vue'
import DestinationCard from '@/components/DestinationCard.vue'
import { getDestination } from '@/kentico-api/destinations.api'
import useSwrv from 'swrv/dist/use-swrv'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import { useHead } from '@vueuse/head'

export default defineComponent({
  components: { PageHeader, DestinationCard, GenericError, CardScroll },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { data, error } = useSwrv(() => props.slug, getDestination)
    const destination = computed(() => data.value?.data)
    const imageUrl = computed(() =>
      destination.value
        ? imageUrlBuilder(destination.value.image.url, {
          auto: 'format',
          w: 541
        })
        : null
    )
    const goToRouteDescription = (routeDescriptionSlug: string, key: string) => {
      router.push(
        {
          name: 'RouteDescription',
          params:
          {
            routeName: routeDescriptionSlug !== '' ? routeDescriptionSlug : key
          }
        }
      )
    }

    useHead({
      meta: [
        {
          name: 'og:description',
          content: computed(() => destination.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => destination.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}destinations/${props.slug}`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() =>
            destination.value && destination.value.openGraphOgImage.length > 0
              ? imageUrlBuilder(destination.value.openGraphOgImage[0].url,
                { w: 800, auto: 'format' })
              : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:locale',
          content: computed(() => destination.value?.openGraphOgLocale || ''),
          key: 'ogLocale'
        }
      ]
    })
    return { goToRouteDescription, destination, imageUrl, error }
  }
})
