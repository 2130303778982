
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RoutesList',
  props: {
    routes: {
      type: Object,
      required: true
    }
  },
  setup () {
    const decodeSlug = (slug: string, key: string) => slug !== '' ? slug : key
    return { decodeSlug }
  }
})
