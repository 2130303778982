import httpClient from './httpClient'
import { AxiosResponse } from 'axios'
import { Image } from '@/models/Image'
import { Asset } from '@/kentico-api'
import { Metadata } from '@/models/Metadata'
import { OpenGraph } from '@/models/OpenGraph'
import { ShortRoute } from '@/models/Route'
import { File } from '@/models/File'
export interface RoutesPage extends Metadata, OpenGraph {
  title: string;
  slug: string;
  image: Image;
  ctaText: string;
  ctaDownloadableItem: Asset[];
  routesList: ShortRoute[];
}

export interface Route {
  name: string;
  key: string;
  locations: string;
  routeDescriptionSlug: string;
}

export interface RouteBaseInfo {
  locations: string;
  number: string;
  routeDescriptionSlug: string;
}

export interface BasicInfo {
  data: string;
}

export interface Timetable {
  key: string;
  timetableFile: File[];
  validFrom: string;
}

export interface RouteDescription extends OpenGraph, Metadata {
  slug: string;
  title: string;
  introduction: string;
  routeStopsServices: string;
  buyingTicketInfo: BasicInfo;
  moreRoutes: ShortRoute[];
  baseInfo: ShortRoute;
  timetables: Timetable[];
}

const getRoutesPage = (): Promise<AxiosResponse<RoutesPage>> =>
  httpClient.get<RoutesPage>('', {
    params: {
      'system.type': 'routes_page',
      'elements.slug': 'index'
    }
  })

const getRouteDescription = (routeName: string): Promise<AxiosResponse<RouteDescription>> =>
  httpClient.get<RouteDescription>('', {
    params: {
      'system.type': 'route_description',
      'elements.slug': routeName.toLowerCase()
    }
  })

export { getRoutesPage, getRouteDescription }
