
/* eslint-disable max-lines-per-function */
import { computed, defineComponent, ref, watchEffect } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import RouteDescriptionSkeleton from '@/components/Skeletons/RouteDescriptionSkeleton.vue'
import GenericError from '@/components/GenericError.vue'
import { getRouteDescription } from '@/kentico-api/routes.api'
import useSwrv from 'swrv/dist/use-swrv'
import Tabs from '@/components/Tabs.vue'
import { TabOption } from '@/models/System'
import RoutesList from '@/components/RoutesList.vue'
import useTitle from '@/composables/useTitle'
import Spinner from '@/components/Spinner.vue'
import TimetableLinkBox from '@/components/TimetableLinkBox.vue'
import { getRouteStops, RouteItinerary } from '@/expressway-api/route.api'
import LegsStops from '@/components/LegsStops.vue'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'

export default defineComponent({
  name: 'RouteDescription',
  components: {
    PageHeader,
    GenericError,
    Tabs,
    RoutesList,
    Spinner,
    RouteDescriptionSkeleton,
    TimetableLinkBox,
    LegsStops
  },
  props: {
    routeName: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line complexity
  setup (props) {
    const { setTitle } = useTitle()
    const tabSelected = ref('route_and_stops')
    const tabsOptions = ref([
      // { value: 'timetable', label: 'Timetable' },
      { value: 'route_and_stops', label: 'Route & stops' }
    ] as TabOption[])
    const updateTabSelected = (newTab: string) => {
      tabSelected.value = newTab
    }
    const { data, error } = useSwrv(
      () => `${props.routeName.toLowerCase()}`, getRouteDescription
    )
    let routeItineraryId = ''
    const loadingRouteItinerary = ref(false)
    const routeItinerary = ref({} as RouteItinerary)
    const updateRouteStops = async () => {
      getRouteStops(routeItineraryId.toLowerCase()).then(res => {
        routeItinerary.value = res
      }).finally(() => {
        loadingRouteItinerary.value = false
      })
    }

    const route = computed(() => {
      if (data?.value && Object.keys(data.value.data).length === 0) {
        return false
      }

      return data.value?.data
    })
    const notFound = computed(() => {
      if (data?.value?.data && Object.keys(data.value.data).length === 0) {
        return true
      }

      if (error.value) {
        return true
      }

      return false
    })

    // eslint-disable-next-line complexity
    watchEffect(() => {
      if (route.value && route.value.metadataPageTitle) {
        setTitle(route?.value.metadataPageTitle)
      }

      if (route.value && route.value.baseInfo.routeItineraryId !== routeItineraryId) {
        ({ routeItineraryId } = route.value.baseInfo)
        loadingRouteItinerary.value = true
        updateRouteStops()
      }
    })

    useHead({
      meta: [
        {
          name: 'og:description',
          content: computed(() => route.value && route.value.openGraphOgDescription
            ? route.value.openGraphOgDescription
            : ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() =>
            route.value && route.value.openGraphOgTitle ? route.value.openGraphOgTitle : ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}routes-and-timetables`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() => route.value && route.value.openGraphOgImage?.length > 0
            ? imageUrlBuilder(route.value.openGraphOgImage[0].url,
              { w: 800, auto: 'format' })
            : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: computed(() => route.value && route.value.openGraphOgTitle
            ? route.value.openGraphOgLocale
            : 'en_IE'),
          key: 'ogLocale'
        }
      ]
    })
    return {
      routeItinerary,
      loadingRouteItinerary,
      notFound,
      data,
      route,
      tabsOptions,
      tabSelected,
      updateTabSelected
    }
  }
})
