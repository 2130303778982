import dayjs from 'dayjs'
import { Journey } from '@/models/Journey'

const stripTags = (text: string) => text.replace(/(<([^>]+)>)/gi, '')

const addEllipses = (text: string) => text.replace(/[^A-Za-z0-9)]*$/, '...')

const truncate = (text: string, maxLength: number) => {
  text = stripTags(text)
  if (text.length <= maxLength) return text

  text = text.substring(0, maxLength + 1)
  text = text.substring(0, text.lastIndexOf(' ')) || text
  return addEllipses(text)
}

const journeySubheading = (journey: Journey, isPartOfRoundtrip = false) => {
  const originName = journey.OriginCityName || journey.OriginStopName
  const destinationName = journey.DestinationCityName || journey.DestinationStopName

  return `${originName} to ${destinationName}` +
  `${isPartOfRoundtrip ? ' (Return)' : ''}` +
  `, ${dayjs(journey.DepartureDateTime).format('MMMM D')}`
}

const formatTimeDiff = (hours: number, minutes: number) => {
  if (hours > 0 && minutes > 0) {
    return `${hours}hr ${minutes} min`
  } else {
    return hours > 0 ? `${hours}hr` : `${minutes} min`
  }
}
const calculateTimeDiff = (fromDate: string, toDate: string) => {
  const to = dayjs(toDate)
  const from = dayjs(fromDate)
  const hours = to.diff(from, 'hour')
  const minutes = to.diff(from, 'minute') % 60
  return formatTimeDiff(hours, minutes)
}

const formatPrice = (price: number) => `€${price.toFixed(2)}`
const formatDate = (date?: string) => date && dayjs(date).format('ddd, D MMM YY')
const altFormatDate = (date?: string) => date && dayjs(date).format('D/MM/YY')
const formatTime = (date?: string) => date && dayjs(date).format('HH:mm')
export {
  stripTags,
  addEllipses,
  truncate,
  journeySubheading,
  formatDate,
  formatTime,
  formatPrice,
  calculateTimeDiff,
  altFormatDate,
  formatTimeDiff
}
