
import { defineComponent, computed } from 'vue'
import useSwrv from 'swrv/dist/use-swrv'
import { getRoutesPage } from '@/kentico-api/routes.api'
import PageHeader from '@/components/PageHeader.vue'
import RouteSkeleton from '@/components/Skeletons/RouteSkeleton.vue'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'

export default defineComponent({
  name: 'Routes',
  components: { PageHeader, RouteSkeleton },
  // eslint-disable-next-line complexity
  setup () {
    const { data, error } = useSwrv(
      () => 'routes_page', getRoutesPage
    )
    const page = computed(() => data.value?.data)
    const downloadable = computed(() =>
      (data.value?.data.ctaDownloadableItem && data.value?.data.ctaDownloadableItem?.length > 0)
        ? data.value.data.ctaDownloadableItem[0].url
        : null)
    const decodeSlug = (slug: string, key: string) => slug !== '' ? slug : key

    useHead({
      meta: [
        {
          name: 'og:description',
          content: computed(() => page.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => page.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}routes-and-timetables`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() => page.value && page.value.openGraphOgImage?.length > 0
            ? imageUrlBuilder(page.value.openGraphOgImage[0].url,
              { w: 800, auto: 'format' })
            : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: computed(() => page.value?.openGraphOgLocale || 'en_IE'),
          key: 'ogLocale'
        }
      ]
    })

    return {
      decodeSlug,
      page,
      error,
      downloadable
    }
  }
})
