import { httpClient } from './httpClient'
import { LegDetailed } from '@/models/Journey'

export interface ItineraryLeg {
  Legs: LegDetailed[];
  RouteName: string;
}
export interface RouteItinerary {
  Outbound: ItineraryLeg;
  Inbound: ItineraryLeg;
}
const getRouteStops = (routeSlug: string): Promise<RouteItinerary> =>
  httpClient
    .get(`/routes/${routeSlug}/stops`)
    .then(response => response.data)
export { getRouteStops }
